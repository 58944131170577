import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Flag from "react-country-flag";
import { SortOrder } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { graphql } from "@/gql";
import Loader from "../Loader/Loader";
import { useSelectCountry } from "@/context/CountryContext";
import { MouseEvent, useMemo, useState } from "react";

const LIST_COUNTRIES = graphql(`
  query ListCountriesQuery(
    $orderBy: [CountryOrderByWithRelationAndSearchRelevanceInput!]
    $where: CountryWhereInput
  ) {
    countries(orderBy: $orderBy, where: $where) {
      id
      name_fr
      alpha2
    }
  }
`);
interface Props {
  cbSelect?: () => void;
}

export default function SelectCountry({ cbSelect }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { countryId, selectCountry } = useSelectCountry();
  const { loading, data, error } = useQuery(LIST_COUNTRIES, {
    variables: {
      orderBy: [{ name_fr: SortOrder.Asc }],
      where: {
        isPublish: {
          equals: true,
        },
      },
    },
  });

  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: MouseEvent<HTMLElement>,
    countryId: number
  ) => {
    selectCountry(countryId);
    setAnchorEl(null);
    cbSelect && cbSelect();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countrySelected = useMemo(() => {
    if (data?.countries?.length) {
      const selectedCountry = data.countries.find((el) => el.id === countryId);
      return selectedCountry || data.countries[0];
    }
    return {
      id: 1,
      name_fr: "Côte d'Ivoire",
      alpha2: "CI",
    };
  }, [countryId, data]);

  if (loading) return <Loader />;
  if (error || !data?.countries?.length) return null;

  return (
    <div>
      <List
        component="nav"
        aria-label="Country selection"
        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <ListItemButton
          id="country-button"
          aria-haspopup="listbox"
          aria-controls="country-menu"
          aria-label="select country"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, height: 40 }}
        >
          <ListItemText
            secondary={
              <span
                style={{
                  color: "white",
                  fontWeight: 400,
                  fontSize: "1rem",
                }}
              >
                <Flag
                  countryCode={
                    countrySelected.alpha2?.toLocaleUpperCase() || "CI"
                  }
                  style={{ width: 20, marginRight: 8 }}
                />
                {countrySelected.name_fr}
              </span>
            }
          />
        </ListItemButton>
      </List>
      <Menu
        id="country-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          zIndex: 999999,
        }}
        MenuListProps={{
          "aria-labelledby": "country-button",
          role: "listbox",
        }}
      >
        {data?.countries?.map((country, index) => (
          <MenuItem
            key={country.id}
            selected={index === index}
            onClick={(event) => handleMenuItemClick(event, country.id)}
          >
            <Flag
              countryCode={country?.alpha2?.toLocaleUpperCase() || "CI"}
              style={{ width: 20, marginRight: 8 }}
            />
            {country.name_fr}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
