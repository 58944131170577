"use client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { CheckCircle, Error as ErrorIcon } from "@mui/icons-material";
import useLang from "@/hooks/useLang";
import { FC } from "react";

interface NotifScan {
  success: boolean;
  ticketName?: string;
  price?: number;
  currency?: string;
  message: string;
  eventId?: number;
}

interface ScanResultProps {
  open: boolean;
  handleClose: () => void;
  notifScan: NotifScan;
}

const ScanResult: FC<ScanResultProps> = ({ open, handleClose, notifScan }) => {
  const { getPrice } = useLang();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          {notifScan.success ? (
            <CheckCircle style={{ color: "green", fontSize: 60 }} />
          ) : (
            <ErrorIcon style={{ color: "red", fontSize: 60 }} />
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {notifScan.ticketName && (
          <Box
            sx={{
              mt: 2,
              backgroundColor: "orange",
              width: "100%",
              p: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Billet:
            </Typography>
            <Typography
              variant="h6"
              sx={{ textTransform: "capitalize", mt: 1 }}
            >
              👉🏿 ​​{notifScan.ticketName}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>
              Prix:
            </Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              👉🏿{" "}
              {getPrice({
                price: notifScan?.price || 0,
                currency: notifScan.currency,
              })}
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography variant="h6">{notifScan.message}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="info">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScanResult;
