import { Box, CircularProgress } from "@mui/material";

const Loader = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}
  >
    <CircularProgress size={30} style={{ color: "white" }} />
  </Box>
);

export default Loader;
