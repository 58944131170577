"use client";

import { useState, useRef, useEffect, FC } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Alert,
  Paper,
  styled,
  CircularProgress,
  Button,
} from "@mui/material";
import { CameraAlt as CameraIcon } from "@mui/icons-material";
import jsQR from "jsqr";

interface ScannerProps {
  open: boolean;
  onClose: () => void;
  onScan: (result: string) => void;
  loading?: boolean;
  primaryColor?: string;
}

const CornerContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "250px",
  height: "250px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const Corner = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "30px",
  height: "30px",
  borderColor: "white",
  borderStyle: "solid",
  "&.top-left": {
    top: 0,
    left: 0,
    borderWidth: "3px 0 0 3px",
    borderTopLeftRadius: "8px",
  },
  "&.top-right": {
    top: 0,
    right: 0,
    borderWidth: "3px 3px 0 0",
    borderTopRightRadius: "8px",
  },
  "&.bottom-left": {
    bottom: 0,
    left: 0,
    borderWidth: "0 0 3px 3px",
    borderBottomLeftRadius: "8px",
  },
  "&.bottom-right": {
    bottom: 0,
    right: 0,
    borderWidth: "0 3px 3px 0",
    borderBottomRightRadius: "8px",
  },
}));

const VideoContainer = styled(Paper)(({ theme }) => ({
  position: "relative",
  width: 300,
  height: 300,
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  backgroundColor: theme.palette.grey[100],
  marginBottom: theme.spacing(2),
}));

const Scanner: FC<ScannerProps> = ({
  open,
  onClose,
  onScan,
  loading,
  primaryColor,
}) => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const scanFrameRef = useRef<number>();
  const [cameraActive, setCameraActive] = useState(false);
  const [isInt, setIsInt] = useState(true);

  const startCamera = async () => {
    try {
      if (typeof navigator !== "undefined") {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: "environment",
            width: { ideal: 640 },
            height: { ideal: 480 },
          },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          streamRef.current = stream;
          setCameraActive(true);
          setIsInt(false);
        }

        setHasPermission(true);
        setError(null);
      }
    } catch (err) {
      setHasPermission(false);
      setCameraActive(false);
      setError(
        "Camera access denied. Please grant permission to use the camera."
      );
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (scanFrameRef.current) {
      cancelAnimationFrame(scanFrameRef.current);
    }
    setCameraActive(false);
  };

  const scanQRCode = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (!context) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height);

    if (code) {
      onScan(code.data);
    }

    scanFrameRef.current = requestAnimationFrame(scanQRCode);
  };

  const restartCamera = () => {
    stopCamera();
    handleStartCamera();
  };

  const handleStartCamera = () => {
    startCamera().then(() => {
      if (videoRef.current) {
        videoRef.current.onloadedmetadata = () => {
          scanFrameRef.current = requestAnimationFrame(scanQRCode);
        };
      }
    });
  };

  useEffect(() => {
    if (open) {
      handleStartCamera();
    }
    return () => {
      stopCamera();
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { position: "relative" },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <Box my={2}>
          <Typography variant="h6" align="center" gutterBottom>
            Scannez votre billet en toute sécurité !
          </Typography>
        </Box>

        <VideoContainer elevation={1}>
          {hasPermission ? (
            <>
              {loading ? (
                <Box textAlign="center">
                  <CircularProgress color="inherit" />
                  <Typography color="text.secondary">
                    Scan du billet en cours...
                  </Typography>
                </Box>
              ) : (
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  disablePictureInPicture
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
              {!cameraActive && isInt && (
                <Button
                  sx={{ position: "absolute", zIndex: 10000 }}
                  style={{
                    background: primaryColor || "black",
                    color: primaryColor ? "black" : "white",
                  }}
                  color="info"
                  variant="contained"
                  onClick={restartCamera}
                >
                  Activer la caméra
                </Button>
              )}
              <canvas ref={canvasRef} style={{ display: "none" }} />
              <CornerContainer>
                <Corner className="top-left" />
                <Corner className="top-right" />
                <Corner className="bottom-left" />
                <Corner className="bottom-right" />
              </CornerContainer>
            </>
          ) : error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <Box textAlign="center">
              <CameraIcon
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography color="text.secondary">
                Initialisation de la caméra...
              </Typography>
            </Box>
          )}
        </VideoContainer>
        {hasPermission && cameraActive && (
          <Typography align="center" variant="body2" sx={{ mt: 2 }}>
            Positionnez le code QR dans le cadre pour le scanner automatiquement
          </Typography>
        )}
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          sx={{ mt: 2, display: "block", mx: "auto" }}
        >
          Fermer
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default Scanner;
