import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Constantes
const DEFAULT_USER = "web-default-user";
const STORAGE_KEY = "anonymousId";

const useAnonymousId = () => {
  // Initialisation avec une valeur par défaut
  const [anonymousId, setAnonymousId] = useState<string>(DEFAULT_USER);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Marquer que nous sommes côté client
    setIsClient(true);

    try {
      // Récupérer l'ID stocké
      const storedId = localStorage.getItem(STORAGE_KEY);

      if (storedId) {
        setAnonymousId(storedId);
      } else {
        // Générer un nouvel ID si aucun n'existe
        const newId = uuidv4();
        localStorage.setItem(STORAGE_KEY, newId);
        setAnonymousId(newId);
      }
    } catch (error) {
      // En cas d'erreur (ex: localStorage désactivé), garder la valeur par défaut
      console.warn('Unable to access localStorage:', error);
    }
  }, []);

  // Retourner un objet immuable
  return {
    anonymousId,
    isClient,
  } as const; // TypeScript const assertion pour plus de type safety
};

export default useAnonymousId;