import { getUserId, removeAuthToken } from "@/utils/authToken";
import { useCallback } from "react";

const useUser = () => {
  const isConected = Boolean(getUserId());
  const userId = getUserId();

  const logout = useCallback(() => {
    removeAuthToken();
  }, []);

  return { isConected, userId, logout };
};

export default useUser;
