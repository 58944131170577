import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Snackbar,
  Button,
  Typography,
  Box,
  Stack,
  Switch,
  FormControlLabel,
  FormGroup,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CookieIcon from "@mui/icons-material/Cookie";

const CookieConsentBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const hasConsent = Cookies.get("cookieConsent");
    if (!hasConsent) {
      setOpen(true);
    }

    const handleScroll = () => {
      setHasScrolled(true);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAcceptAll = () => {
    setPreferences({
      necessary: true,
      analytics: true,
      marketing: true,
    });
    Cookies.set("cookieConsent", JSON.stringify(preferences), {
      expires: 365,
      sameSite: "Strict",
      secure: true,
    });

    if (preferences.analytics) {
      Cookies.set("_ga", "G-PXFKX0MQT2", {
        expires: 365,
        sameSite: "Strict",
        secure: true,
      });
    }

    setOpen(false);
  };

  const handleDecline = () => {
    setPreferences({
      necessary: true,
      analytics: false,
      marketing: false,
    });
    Cookies.set("cookieConsent", "necessary", {
      expires: 365,
      sameSite: "Strict",
      secure: true,
    });
    setOpen(false);
  };

  const handleSavePreferences = () => {
    Cookies.set("cookieConsent", JSON.stringify(preferences), {
      expires: 365,
      sameSite: "Strict",
      secure: true,
    });

    if (preferences.analytics) {
      Cookies.set("_ga", "G-PXFKX0MQT2", {
        expires: 365,
        sameSite: "Strict",
        secure: true,
      });
    }

    setOpen(false);
  };

  const renderContent = () => {
    return showPreferences ? (
      <Box sx={{ width: "100%", p: 2 }}>
        <FormGroup>
          <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Cookies nécessaires
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Ces cookies sont essentiels au fonctionnement du site. Ils ne
                peuvent pas être désactivés.
              </Typography>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
            <CardContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={preferences.analytics}
                    onChange={(e) =>
                      setPreferences((prev) => ({
                        ...prev,
                        analytics: e.target.checked,
                      }))
                    }
                    color="primary"
                  />
                }
                label={
                  <Box>
                    <Typography variant="subtitle1">
                      Cookies analytiques
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Pour analyser le trafic et améliorer notre site.
                    </Typography>
                  </Box>
                }
                sx={{ alignItems: "flex-start", ml: 0 }}
              />
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
            <CardContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={preferences.marketing}
                    onChange={(e) =>
                      setPreferences((prev) => ({
                        ...prev,
                        marketing: e.target.checked,
                      }))
                    }
                    color="primary"
                  />
                }
                label={
                  <Box>
                    <Typography variant="subtitle1">
                      Cookies marketing
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Pour vous proposer des publicités personnalisées.
                    </Typography>
                  </Box>
                }
                sx={{ alignItems: "flex-start", ml: 0 }}
              />
            </CardContent>
          </Card>
        </FormGroup>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ pt: 2 }}
        >
          <Button color="inherit" onClick={() => setShowPreferences(false)}>
            Retour
          </Button>
          <Button
            variant="contained"
            onClick={handleSavePreferences}
            disableElevation
            sx={{
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            Enregistrer les préférences
          </Button>
        </Stack>
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "stretch", sm: "center" },
          width: "100%",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            mb: { xs: 2, sm: 0 },
          }}
        >
          <CookieIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1">
            Nous utilisons des cookies pour améliorer votre expérience, analyser
            le trafic, personnaliser le contenu et les publicités, et offrir des
            fonctionnalités sociales.
          </Typography>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          sx={{ width: { xs: "100%", sm: "auto" } }}
        >
          <Button color="inherit" onClick={handleDecline}>
            Refuser
          </Button>
          <Button color="inherit" onClick={() => setShowPreferences(true)}>
            Personnaliser
          </Button>
          <Button
            variant="contained"
            onClick={handleAcceptAll}
            disableElevation
            sx={{
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            Tout accepter
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <Snackbar
      open={open && hasScrolled}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message={renderContent()}
      sx={{
        width: "100%",
        maxWidth: 900,
        position: "fixed",
        left: "50%",
        bottom: { xs: 0, sm: 24 },
        transform: { xs: "translateX(-50%)", sm: "translateX(-50%)" },
        "& .MuiSnackbarContent-root": {
          width: { xs: "100vw", sm: "100%" },
          maxWidth: 900,
          borderRadius: { xs: 0, sm: 2 },
          boxShadow: 3,
        },
      }}
    />
  );
};

export default CookieConsentBanner;
