import { FC, memo } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Divider,
  Container,
} from "@mui/material";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { styled } from "@mui/system";
import "./Footer.css";
import Link from "next/link";
import dynamic from "next/dynamic";
import ModalScanner from "../ModalScanner/ModalScanner";

const DownloadButtuon = dynamic(
  () => import("../DownloadButton/DownloadButton")
);

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  color: "#fff",
  padding: "4rem 2rem",
  position: "relative",
  zIndex: 1,
}));

const FooterOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background:
    "linear-gradient(135deg, rgba(255,255,255,0.05) 25%, transparent)",
  zIndex: -1,
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: "1rem",
  fontSize: "1rem",
  textTransform: "uppercase",
  letterSpacing: "0.1rem",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#fff",
  textDecoration: "none",
}));

const SocialContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1.5rem",
  marginTop: "2rem",
}));

const Footer: FC = () => {
  return (
    <FooterContainer>
      <FooterOverlay />
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* About Section */}
          <Grid item xs={12} md={4}>
            <FooterTitle>À Propos</FooterTitle>
            <Typography variant="body2" sx={{ marginBottom: "1.5rem" }}>
              Notre plateforme permet d'accéder à des événements en ligne de
              classe mondiale. Que vous soyez fan de musique, de sport, ou
              d'éducation, nous vous connectons avec les meilleures expériences.
            </Typography>
          </Grid>

          {/* Useful Links */}
          <Grid item xs={12} md={4}>
            <FooterTitle>Liens Utiles</FooterTitle>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <FooterLink className="link" href="/">
                  Accueil
                </FooterLink>
              </li>
              <li>
                <FooterLink className="link" href="/a-propos">
                  À propos
                </FooterLink>
              </li>
              <li>
                <FooterLink className="link" href="/telechargement">
                  Téléchargement
                </FooterLink>
              </li>
              <li>
                <FooterLink className="link" href="/contact">
                  Nous contacter
                </FooterLink>
              </li>
              <li>
                <FooterLink className="link" href="/condition-utilisation">
                  Conditions d'utilisation
                </FooterLink>
              </li>
            </ul>
          </Grid>

          {/* App Download Links */}
          <Grid item xs={12} md={4}>
            <FooterTitle>Téléchargez l'application</FooterTitle>
            <Typography variant="body2" sx={{ marginBottom: "1.5rem" }}>
              Téléchargez notre application mobile pour ne manquer aucun
              événement et rester à jour avec les dernières offres exclusives.
            </Typography>
            <DownloadButtuon />
          </Grid>
        </Grid>

        {/* Social Media Icons */}
        <Divider sx={{ my: 4, backgroundColor: "#fff" }} />
        <SocialContainer>
          <IconButton
            component="a"
            color="inherit"
            href="https://web.facebook.com/profile.php?id=61565893673319&_rdc=1&_rdr"
            target="_blank"
            sx={{ background: "#fff" }}
            className="link-social"
          >
            <Facebook sx={{ color: "black" }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.instagram.com/lehubs_event/"
            target="_blank"
            sx={{ background: "#fff" }}
            className="link-social"
          >
            <Instagram sx={{ color: "black" }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.youtube.com/@lehubs/videos"
            target="_blank"
            sx={{ background: "#fff" }}
            className="link-social"
          >
            <YouTube sx={{ color: "black" }} />
          </IconButton>
        </SocialContainer>

        {/* Copyright */}
        <Box textAlign="center" mt={4}>
          <Typography variant="body2" color="inherit">
            © {new Date().getFullYear()} Le Hub. Tous droits réservés.
          </Typography>
        </Box>
      </Container>
      <ModalScanner />
    </FooterContainer>
  );
};

export default memo(Footer);
