import { Button, ButtonProps } from "@mui/material";
import { SxProps, Theme } from "@mui/material";

interface Props extends ButtonProps {
  customSx?: SxProps<Theme>;
  label?: string;
}

export default function CustomButton({
  customSx,
  children,
  label,
  ...props
}: Props) {
  return (
    <Button
      variant="contained"
      disableElevation
      sx={{
        borderRadius: 20,
        background: "black",
        ":hover": {
          opacity: 0.75,
        },
        ...customSx,
      }}
      {...props}
    >
      {label ? label : children}
    </Button>
  );
}
