"use client";

import { CSSProperties, useState } from "react";
import { Fab } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";
import { TicketResponseStatus } from "@/gql/graphql";
import useUser from "@/hooks/useUser";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

const ModalInfoConnection = dynamic(
  () => import("@/components/ModalInfoConnection/ModalInfoConnection")
);
const ScanResult = dynamic(() => import("./ScanResult/ScanResult"));
const ScannerModal = dynamic(() => import("./Scanner/Scanner"));

const SCAN_EVENT = graphql(`
  mutation UseTicket($qrCode: String!) {
    useTicket(qrCode: $qrCode) {
      message
      success
      ticket {
        id
        createdAt
        updatedAt
        eventId
        id
        isUsed
        user {
          id
        }
        ticketPricing {
          id
          currentUserCurrency
          priceByUser
          name
        }
      }
    }
  }
`);

const fabStyle: CSSProperties = {
  position: "fixed",
  bottom: 140,
  right: 30,
  backgroundColor: "white"
};

interface Props {
  primaryColor?: string;
}

export default function ModalScanner({ primaryColor }: Props) {
  const { isConected } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [scanEvent] = useMutation(SCAN_EVENT);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const pathname = usePathname();

  const scanMessage = {
    QrCodeNotHub: "Le code QR ne correspond pas au type de ticket",
    TicketIsAlreadyUse: "Ce ticket a déjà été utilisé.",
    TicketExpired: "Ce ticket a expiré.",
    TicketNotFound: "Ticket introuvable.",
    UserUnautorizeScanTicket: "Vous n'êtes pas autorisé à scanner ce ticket.",
    TicketIsCompletedUse: "Entrée validée",
    EventNotStarted: "L'événement n'a pas encore commencé.",
  };

  const [notifScan, setNotifScan] = useState({
    message: scanMessage["QrCodeNotHub"],
    success: false,
    eventId: 0,
    ticketName: "",
    price: 0,
    currency: "",
  });

  const handleQrCodeScanned = (qrCode: string) => {
    setLoading(true);
    scanEvent({
      variables: {
        qrCode,
      },
      onCompleted(data) {
        const ticket = data.useTicket;
        switch (ticket.message) {
          case TicketResponseStatus.EventNotStarted:
            setNotifScan({
              message: scanMessage["EventNotStarted"],
              success: false,
              eventId: 0,
              ticketName: "",
              price: 0,
              currency: "",
            });
            break;
          case TicketResponseStatus.QrCodeNotHub:
            setNotifScan({
              message: scanMessage["QrCodeNotHub"],
              success: false,
              eventId: 0,
              ticketName: "",
              price: 0,
              currency: "",
            });
            break;
          case TicketResponseStatus.TicketIsAlreadyUse:
            setNotifScan({
              message: scanMessage["TicketIsAlreadyUse"],
              success: false,
              eventId: 0,
              ticketName: "",
              price: 0,
              currency: "",
            });
            break;
          case TicketResponseStatus.TicketExpired:
            setNotifScan({
              message: scanMessage["TicketExpired"],
              success: false,
              eventId: 0,
              ticketName: "",
              price: 0,
              currency: "",
            });
            break;
          case TicketResponseStatus.TicketNotFound:
            setNotifScan({
              message: scanMessage["TicketNotFound"],
              success: false,
              eventId: Number(ticket.ticket?.id),
              ticketName: "",
              price: 0,
              currency: "",
            });
            break;
          case TicketResponseStatus.UserUnautorizeScanTicket:
            setNotifScan({
              message: scanMessage["UserUnautorizeScanTicket"],
              success: false,
              eventId: 0,
              ticketName: "",
              price: 0,
              currency: "",
            });
            break;
          case TicketResponseStatus.TicketIsCompletedUse:
            setNotifScan({
              message: scanMessage["TicketIsCompletedUse"],
              success: true,
              eventId: Number(ticket.ticket?.id),
              ticketName: ticket?.ticket?.ticketPricing?.name || "",
              price: ticket?.ticket?.ticketPricing?.priceByUser || 0,
              currency:
                ticket?.ticket?.ticketPricing?.currentUserCurrency || "XOF",
            });
            break;
        }
        setLoading(false);
        setIsOpen(false);
        setIsModalVisible(true);
      },
      onError(error) {
        setNotifScan({
          message: scanMessage["QrCodeNotHub"],
          success: false,
          eventId: 0,
          ticketName: "",
          price: 0,
          currency: "",
        });
        setLoading(false);
        setIsOpen(false);
        setIsModalVisible(true);
      },
    });
  };

  if (pathname.endsWith("/billets")) {
    return null;
  }

  return (
    <>
      <Fab
        color="inherit"
        aria-label="scan"
        onClick={() => {
          if (isConected) {
            setIsOpen(true);
          } else {
            setShow(true);
          }
        }}
        sx={fabStyle}
      >
        <QrCodeScannerIcon sx={{ color: "black" }} />
      </Fab>
      <ScannerModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onScan={handleQrCodeScanned}
        loading={loading}
        primaryColor={primaryColor}
      />
      <ScanResult
        handleClose={() => setIsModalVisible(false)}
        open={isModalVisible}
        notifScan={notifScan}
      />
      <ModalInfoConnection handleClose={() => setShow(false)} open={show} />
    </>
  );
}
