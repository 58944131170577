import { useIntl } from "react-intl";

const useLang = () => {
  const intl = useIntl();
  const getPrice = ({
    price,
    currency = "XOF",
  }: {
    price: string | number;
    currency?: string;
  }) => {
    const priceValue = intl.formatNumber(Number(price), {
      currency: currency,
      currencyDisplay: "narrowSymbol",
      style: "currency",
    });
    return priceValue;
  };

  return {
    getPrice,
  };
};

export default useLang;
