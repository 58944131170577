import { Box, Typography, Button, Container } from "@mui/material";
import Link from "next/link";

interface Props {
  message?: string;
  code?: string;
  subMessage?: string;
  href?: string;
  bouttonMessage?: string;
}
const NotFound = ({
  message = "On dirait que tu es perdu",
  code = "404",
  subMessage = "La page que vous recherchez n'est pas disponible !",
  href = "/",
  bouttonMessage = "Aller à la page d'accueil",
}: Props) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontSize: "10rem",
            fontWeight: 700,
            color: "black",
            marginBottom: "20px",
          }}
        >
          {code}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{ color: "black" }}
        >
          {message}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ color: "black" }}
        >
          {subMessage}
        </Typography>
        <Box display="flex" justifyContent="center" mt={4}>
          <Link href={href} passHref>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ color: "white" }}
            >
              {bouttonMessage}
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
