"use client";

import DotsLoading from "@/components/Commons/DotsLoading/DotsLoading";
import NotFound from "@/components/NotFound/NotFound";
import { graphql } from "@/gql";
import { SectionUi } from "@/gql/graphql";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  FC,
  useEffect,
} from "react";

interface Compagny {
  email: string;
  facebookUrl?: string | null;
  id: number;
  instagramUrl?: string | null;
  logoUrl: string;
  name: string;
  ownerId: number;
  primaryColor: string;
  secondaryColor: string;
  slug: string;
  tiktokUrl?: string | null;
  youtubeUrl?: string | null;
  sectionUis?: SectionUi[];
}

type CompagnyContextContextType = {
  selectCompagnyContext: (compagnyCompagny: Compagny) => void;
  compagny: Compagny;
  loading: boolean;
  error?: ApolloError;
  getSectionUi: (section: string) => SectionUi | null | undefined;
  updateSectionUi: (section: string, data: SectionUi) => void;
};

const defaultCompgany: Compagny = {
  email: "",
  id: 0,
  logoUrl: "/logo.webp",
  name: "",
  ownerId: 0,
  primaryColor: "#000000",
  secondaryColor: "#ffffff",
  slug: "",
};

const CompagnyContextContext = createContext<CompagnyContextContextType>({
  compagny: defaultCompgany,
  selectCompagnyContext() {},
  loading: false,
  getSectionUi: () => null,
  updateSectionUi() {},
});

export const useSelectCompagnyContext = (): CompagnyContextContextType => {
  const context = useContext(CompagnyContextContext);
  if (!context) {
    throw new Error(
      "useSelectCompagnyContext must be used within a CompagnyContextProvider"
    );
  }
  return context;
};

type CompagnyContextProviderProps = {
  children: ReactNode;
  compagnySlug: string;
};

const COMPAGNY = graphql(`
  query GetCompagny($where: CompagnyWhereUniqueInput!) {
    getCompagny(where: $where) {
      id
      instagramUrl
      facebookUrl
      email
      logo {
        url
      }
      name
      ownerId
      primaryColor
      secondaryColor
      slug
      tiktokUrl
      youtubeUrl
      sectionUI {
        bgColor
        btnColor
        btnTextColor
        compganyId
        id
        section
        textColor
        updatedAt
      }
    }
  }
`);

export const CompagnyContextProvider: FC<CompagnyContextProviderProps> = ({
  children,
  compagnySlug,
}) => {
  const [compagny, setCompagnyContextState] =
    useState<Compagny>(defaultCompgany);

  const [getCompagny, { loading, error, data }] = useLazyQuery(COMPAGNY);
  const selectCompagnyContext = useCallback((compgany: Compagny) => {
    setCompagnyContextState(compgany);
  }, []);

  const getSectionUi = useCallback(
    (section: string) => {
      return compagny.sectionUis?.find((el) => el.section === section);
    },
    [compagny]
  );

  const updateSectionUi = useCallback((section: string, data: SectionUi) => {
    setCompagnyContextState((prev) => {
      const sections = prev.sectionUis ? [...prev.sectionUis] : [];

      const index = sections.findIndex((el) => el.section === section);
      if (index > -1) {
        sections[index] = data; // Mise à jour de l'élément existant
      } else {
        sections.push(data); // Ajout d'un nouvel élément
      }

      return {
        ...prev,
        sectionUis: sections,
      };
    });
  }, []);

  useEffect(() => {
    getCompagny({
      variables: {
        where: {
          slug: compagnySlug,
        },
      },
      onCompleted(data) {
        if (data.getCompagny) {
          const { logo, sectionUI, ...rest } = data.getCompagny;
          setCompagnyContextState({
            ...rest,
            logoUrl: logo?.url || "/logo.webp",
            sectionUis: (sectionUI as SectionUi[]) || [],
          });
        }
      },
    });
  }, [compagnySlug]);

  if (loading) return <DotsLoading bgColor="white" animationColor="#0096FF" />;
  if (error)
    return (
      <Box
        sx={{
          py: 4,
          my: 0,
          borderRadius: 0,
          minHeight: "100vh",
          bgcolor: "white",
        }}
      >
        <NotFound />
      </Box>
    );

  return (
    <CompagnyContextContext.Provider
      value={{
        compagny,
        selectCompagnyContext,
        loading,
        error,
        getSectionUi,
        updateSectionUi,
      }}
    >
      {children}
    </CompagnyContextContext.Provider>
  );
};
