import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useRouter } from "next/navigation";
import { Fragment } from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
  url?: string;
}

export default function ModalInfoConnection({ handleClose, open, url }: Props) {
  const router = useRouter();

  const handleOpen = () => {
    handleClose();
    router.push(
      `/connexion?url=${url ? url : window.location.href + "?commander=oui"}`
    );
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Connexion requise</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous devez vous connecter pour accéder à cette fonctionnalité.
            Veuillez cliquer sur le bouton de connexion ci-dessous.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Fermer
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleOpen();
            }}
            autoFocus
          >
            Connexion
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
