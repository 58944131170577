import { Box } from "@mui/material";
import dynamic from "next/dynamic";
import { memo } from "react";

const CustomButton = dynamic(() => import("../CustomButton/CustomButton"));

const DownloadButton = ({
  backgroundColor = "white",
  color = "black",
  textColor = "black",
}: {
  backgroundColor?: string;
  color?: string;
  textColor?: string;
}) => {
  return (
    <Box display="flex" gap="1rem">
      <CustomButton
        customSx={{ backgroundColor, color }}
        //@ts-ignore
        target="_blank"
        href="https://apps.apple.com/fr/app/lehubs/id6477760847"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          style={{ marginRight: "5px", color }}
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.91 2s-1.794.008-3.082 1.566h.001c-1.147 1.386-.855 2.572-.855 2.572s1.556.248 2.922-1.26C16.176 3.466 15.91 2 15.91 2Zm-2.745 4.88c-.422.156-.768.284-1.028.284-.324 0-.715-.138-1.169-.298-.63-.222-1.38-.487-2.243-.487C6.439 6.379 4 8.255 4 11.835 4 15.474 6.879 20 9.16 20c.354 0 .793-.146 1.28-.308.606-.2 1.286-.427 1.969-.427.611 0 1.142.182 1.666.362.51.175 1.014.347 1.58.347 2.496 0 4.345-4.772 4.345-4.772s-2.643-.94-2.643-3.662c0-2.416 2.122-3.412 2.122-3.412s-1.086-1.8-3.846-1.8c-.975 0-1.813.31-2.468.552Z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span style={{ color: textColor }}>iOS</span>
      </CustomButton>
      <CustomButton
        //@ts-ignore
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.lebarit.lehub&pcampaignid=web_share"
        customSx={{ backgroundColor, color }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          style={{ marginRight: "5px", color }}
        >
          <path
            fill="currentColor"
            d="M4 11a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0v-4ZM18 11a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0v-4ZM7 10h10v7a1 1 0 0 1-1 1h-1v2a1 1 0 1 1-2 0v-2h-2v2a1 1 0 1 1-2 0v-2H8a1 1 0 0 1-1-1v-7Z"
          ></path>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.776 2.553a.5.5 0 0 1 .671.223l.775 1.55A4.99 4.99 0 0 1 12 4a4.99 4.99 0 0 1 1.778.325l.775-1.549a.5.5 0 1 1 .894.448l-.775 1.55A4.996 4.996 0 0 1 17 9H7c0-1.779.929-3.34 2.328-4.227l-.775-1.55a.5.5 0 0 1 .223-.67ZM10 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span style={{ color: textColor }}>Android</span>
      </CustomButton>
    </Box>
  );
};

export default memo(DownloadButton);
