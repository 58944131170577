import { useSnackbar } from "@/context/SnackbarContext";
import { graphql } from "@/gql";
import { Platform, SocialAccountType } from "@/gql/graphql";
import { setAuthToken, setUserId } from "@/utils/authToken";
import { useMutation } from "@apollo/client";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useParams } from "next/navigation";
import { jwtDecode } from "jwt-decode";

const GOOGLE_MUTATION = graphql(`
  mutation SocialCustomer($socialInput: SocialInput!) {
    socialCustomer(SocialInput: $socialInput) {
      token
      user {
        id
        email
        name
        lastName
      }
    }
  }
`);

const GoogleOneTapLogin = () => {
  const [handleGoogleAuth] = useMutation(GOOGLE_MUTATION);
  const { showSnackbar } = useSnackbar();
  const { compagny } = useParams();

  const handleError = (
    message = "Oups ! Une erreur est survenue. Veuillez réessayer."
  ) => {
    showSnackbar(message, "error");
  };

  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      try {
        const decodedToken: any = jwtDecode(
          credentialResponse?.credential || ""
        );

        if (!decodedToken || (decodedToken && !decodedToken?.email)) {
          throw new Error(
            "Erreur lors de la récupération des informations utilisateur."
          );
        }

        const { email, sub, given_name, family_name, picture } =
          decodedToken as any;

        // Envoi des données à l'API
        const { data } = await handleGoogleAuth({
          variables: {
            socialInput: {
              email: email || "",
              socialAccountType: SocialAccountType.Google,
              platform: Platform.Android,
              socialID: sub,
              lastName: family_name || "",
              name: given_name || "",
              profile: picture || "",
            },
          },
        });

        if (data?.socialCustomer) {
          // Enregistrement des informations utilisateur
          setUserId(data.socialCustomer.user.id);
          setAuthToken(data.socialCustomer.token);

          // Redirection vers le profil
          const redirectPath = compagny ? `/${compagny}/profile` : "/profile";
          window.location.replace(redirectPath);
        } else {
          handleError("Une erreur est survenue, veuillez réessayer.");
        }
      } catch (error: any) {
        handleError(error.message);
      }
    },
    onError: () => {
      handleError();
    },
  });

  return <div />;
};

export default GoogleOneTapLogin;
