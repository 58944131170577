import Script from 'next/script'
import { useState } from 'react'

const TawkToChat = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  // Function to handle the Tawk_API initialization
  const initializeTawkTo = () => {
    try {
      if (typeof window !== 'undefined' && !isLoaded) {
        setIsLoaded(true)
      }
    } catch (error) {
      console.error('Error initializing TawkTo:', error)
    }
  }

  return (
    <Script
      src="https://embed.tawk.to/6775993349e2fd8dfe016e3e/1ighlstee"
      strategy="afterInteractive"
      onLoad={() => {
        initializeTawkTo()
      }}
    />
  )
}

export default TawkToChat