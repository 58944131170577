"use client";

import {
  useCallback,
  useMemo,
  useState,
  Suspense,
  ChangeEvent,
  useEffect,
  useRef,
} from "react";
import {
  Box,
  CircularProgress,
  Container,
  Backdrop,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { v4 as uuidv4 } from "uuid";
import dynamic from "next/dynamic";
import { Event } from "@/gql/graphql";
import useAnonymousId from "@/hooks/useAnonymousId";
import { useSelectCountry } from "@/context/CountryContext";
import "./HomePage.css";

// Constants moved outside the component
const QUERY_SIZE = 10;
const SEARCH_THRESHOLD = 3;
const DEBOUNCE_DELAY = 500;
const DEFAULT_ANONYMOUS_ID = "web-default-user";

// Dynamically loaded components with loading fallback
const LoadingFallback = () => <Box sx={{ height: 50 }} />;

const InfiniteScroll = dynamic(
  () => import("react-infinite-scroll-component"),
  {
    loading: LoadingFallback,
    ssr: false,
  }
);

const CategoryMenu = dynamic(() => import("./CategoryMenu/CategoryMenu"), {
  loading: LoadingFallback,
  ssr: false,
});

const EventGrid = dynamic(() => import("./EventGrid/EventGrid"), {
  loading: LoadingFallback,
  ssr: false,
});

const SearchField = dynamic(() => import("./SearchField/SearchField"), {
  loading: LoadingFallback,
  ssr: false,
});

// GraphQL query moved to a separate file
const EVENTS = graphql(`
  query GetEventExplored(
    $take: Int!
    $skip: Int!
    $name: String
    $categoryIds: [Int!]
    $anonymousId: String
    $countryId: Int
  ) {
    getEventExplored(
      take: $take
      skip: $skip
      name: $name
      categoryIds: $categoryIds
      countryId: $countryId
    ) {
      count
      events {
        id
        title
        startedDate
        endedDate
        startedTime
        note
        endedTime
        descriptions
        countUserInterestEvent
        currentUserIsInterest(anonymousId: $anonymousId)
        eventPrices {
          currentUserCurrency
          eventPublicPriceByUser
        }
        coverImage {
          id
          url
        }
        categories {
          id
          coverImage {
            id
            url
          }
        }
      }
    }
  }
`);

const Home = () => {
  const { anonymousId, isClient } = useAnonymousId();
  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { countryId } = useSelectCountry();

  const queryVariables = useMemo(
    () => ({
      skip: 0,
      countryId,
      take: QUERY_SIZE,
      anonymousId: anonymousId || DEFAULT_ANONYMOUS_ID,
      ...(query && { name: query }),
      ...(selectedCategory && { categoryIds: [selectedCategory] }),
    }),
    [anonymousId, query, selectedCategory, countryId]
  );

  const { data, loading, fetchMore, refetch, previousData } = useQuery(EVENTS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    returnPartialData: true,
    ssr: false,
  });

  const hasMore = useMemo(
    () =>
      (data?.getEventExplored?.events?.length ?? 0) <
      (data?.getEventExplored.count ?? 0),
    [data?.getEventExplored?.events?.length, data?.getEventExplored.count]
  );

  const handleFetchMore = useCallback(() => {
    if (loading || !hasMore) return;

    const currentLength = data?.getEventExplored.events.length || 0;
    fetchMore({
      variables: {
        ...queryVariables,
        skip: currentLength,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getEventExplored: {
            ...fetchMoreResult.getEventExplored,
            events: [
              ...prev.getEventExplored.events,
              ...fetchMoreResult.getEventExplored.events,
            ],
          },
        };
      },
    });
  }, [
    data?.getEventExplored.events.length,
    loading,
    fetchMore,
    hasMore,
    queryVariables,
  ]);

  const debouncedRefetch = useRef(
    debounce((variables) => refetch(variables), DEBOUNCE_DELAY)
  ).current;

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();
      setQuery(value);

      if (value.length >= SEARCH_THRESHOLD) {
        debouncedRefetch(queryVariables);
      }
    },
    [debouncedRefetch, queryVariables]
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    const storedAnonymousId = localStorage.getItem("anonymousId") || uuidv4();
    if (!localStorage.getItem("anonymousId")) {
      localStorage.setItem("anonymousId", storedAnonymousId);
    }
  }, []);

  const loadingInit = useMemo(
    () => Boolean(loading && !previousData?.getEventExplored.count),
    [loading, previousData?.getEventExplored.count]
  );

  return (
    <Box sx={{ flex: 1, bgcolor: "black", color: "white", p: 2 }}>
      <Container maxWidth="lg">
        <Box
          component="header"
          sx={{
            mb: 2,
            position: "sticky",
            top: "5.85vh",
            zIndex: 999,
            background: "rgba(0, 0, 0, 0.9)",
            paddingTop: isDesktop ? "5px" : "20px",
            paddingBottom: "10px",
          }}
        >
          <Suspense fallback={<LoadingFallback />}>
            <SearchField value={query} onChange={handleSearch} />
            {!loadingInit && isClient && (
              <CategoryMenu getSelectedCategory={setSelectedCategory} />
            )}
          </Suspense>
        </Box>
        <Box component="main" sx={{ flex: 1, my: 5 }}>
          <InfiniteScroll
            dataLength={data?.getEventExplored?.events?.length || 0}
            next={handleFetchMore}
            hasMore={Boolean(hasMore)}
            loader={false}
            refreshFunction={refetch}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            scrollThreshold={0.5}
          >
            <EventGrid
              events={(data?.getEventExplored?.events as Event[]) || []}
              anonymousId={anonymousId}
              loadingInit={loadingInit}
            />
          </InfiniteScroll>
        </Box>

        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading && !loadingInit}
        >
          <CircularProgress size={30} style={{ color: "white" }} />
        </Backdrop>
      </Container>
    </Box>
  );
};

export default Home;
